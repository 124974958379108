<template></template>
<script>
import { mapState } from "vuex";

export default {
  data: (vm) => ({
    items: [],
    totalItems: 0,
    urbans: [],
    choose_urban: null,
  }),

  computed: {
    ...mapState(["token"]),
  },
  methods: {
    getTranscribe() {
      //   console.log("getTranscribe in Mixin");
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/summary/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          urban: this.choose_urban.id,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.onTranscribeFinal();
        });
    },
    setResult(data) {
      this.items = data;
      this.totalItems = data.length;
    },
    onTranscribeFinal() {},
    getUrbanRenewal(add_option = false) {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setUrbans(response.data, add_option);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-urban-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setUrbans(data, add_option) {
      if (add_option) {
        data["results"].unshift({
          id: null,
          name: this.$t("plan.create_new_urban_renewal"),
        });
      }
      this.urbans = data["results"];
    },
    forceFileDownload(response, filename) {
      console.log("forceFileDownload:", filename);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
