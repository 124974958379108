<template>
  <v-card>
    <v-card-title class="text-h3 px-2 mb-4">
      上傳檔案初步解析 資訊如下
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="file in pdf_files"
          :key="file.name"
          cols="12"
          class="py-0"
        >
          <v-label>{{ $t("plan.filename") }}:</v-label>
          <v-text-field :value="file" readonly underlined dense></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            :label="$t('user_mgr.balance')"
            :value="user_balance"
            readonly
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('transcript.ownersNum')"
            :value="owner_count"
            readonly
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('plan.pay_amount')"
            :value="charge"
            readonly
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onConfirm">支付</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "OwnerChargeComponent",
  props: {
    owner_count: {
      type: Number,
      required: true,
    },
    charge: {
      type: Number,
      required: true,
    },
    user_balance: {
      type: Number,
      required: true,
    },
    pdf_files: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onConfirm() {
      this.$emit("onPay");
    },
  },
};
</script>
