<template>
  <v-container fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t(prop_title)"
      class="px-5 py-3"
    >
      <!-- import to urban dialog -->
      <v-dialog v-model="import_dialog" max-width="50%">
        <v-card>
          <v-card-title>請選擇匯入都更會</v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedOption">
              <v-row no-gutters>
                <v-radio label="新增更新會" :value="1"></v-radio>
                <v-col cols="8" class="mx-4">
                  <!-- text-field -->
                  <v-text-field
                    v-model="new_urban_name"
                    :label="$t('plan.newUrbanName')"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-radio label="匯入現有" :value="2"></v-radio>
                <v-col cols="8" class="mx-4" v-if="selectedOption === 2">
                  <v-select
                    v-model="choose_urban"
                    :items="urbans"
                    item-text="name"
                    item-value="id"
                    :label="$t('issue.chooseUrban')"
                    hide-details
                    persistent-hint
                    return-object
                    filled
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="import_dialog = false"
              >取消</v-btn
            >
            <v-btn color="blue darken-1" text @click="importToUrban"
              >匯入</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end of import to urban dialog -->
      <dialog-progress
        :dialog_message="isParsing"
        :message="message"
      ></dialog-progress>
      <!-- main -->
      <v-card-title>
        <v-file-input
          v-model="selectedFiles"
          label="選擇PDF文件"
          multiple
          accept="application/pdf"
          :show-size="true"
          class="mx-2"
          :truncate-length="100"
          @change="handleFileChange"
        ></v-file-input>

        <v-btn
          color="primary"
          @click="uploadFiles"
          class="mb-6"
          :disabled="!selectedFiles.length"
        >
          上傳文件
        </v-btn>
        <!-- add reset btn -->
        <v-btn color="default" @click="reset()" class="mb-6"> 重置 </v-btn>
      </v-card-title>
      <owner-charge-component
        v-if="isParsed"
        :owner_count="owner_count"
        :charge="charge"
        :user_balance="user_balance"
        :pdf_files="pdf_files"
        @onPay="onPay"
      ></owner-charge-component>
      <v-card-title v-if="isCompleted">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          dark
          class="mx-2"
          @click="showImportUrbanDialog"
        >
          <v-icon left>mdi-pencil-plus</v-icon>
          {{ $t("common.import") }}
        </v-btn>
      </v-card-title>
      <v-card-title v-if="isProcessing">
        <!-- add label OCR -->
        <v-label>OCR</v-label>
        <v-progress-linear :value="uploadProgress" height="25" striped>
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
      </v-card-title>
      <transcript
        v-if="isShowTranscript"
        :propTranscripts="transcripts"
        ref="transcript"
      ></transcript>
    </base-material-card>
  </v-container>
</template>

<script>
import Transcript from "@/views/dashboard/pages/Transcript";
import OwnerChargeComponent from "@/views/dashboard/pages/OwnerChargeComponent.vue";
import UrbanMixin from "../common/UrbanMixin.vue";
import { mapState } from "vuex";
import { SocketEventType } from "@/definition";
import { ImportState } from "@/definition";

export default {
  props: {
    prop_title: {
      type: String,
      default: "title.Transcribe",
    },
  },
  mixins: [UrbanMixin],
  data() {
    return {
      selectedFiles: [],
      uploadProgress: 0,
      message: {
        title: this.$t("plan.transcribe"),
        text: "謄本解析中...",
      },
      transcripts: [],
      regnos_count: 0,
      processed: 0,
      last_parsed_filenames: null,
      SocketEventType: SocketEventType,
      // import urban dialog
      selectedOption: 1,
      import_dialog: false,
      new_urban_name: "",
      // charge
      ImportState: ImportState,
      status: ImportState.Init,
      owner_count: 0,
      charge: 0,
      pdf_files: [],
      user_balance: 0,
    };
  },
  computed: {
    ...mapState(["token"]),
    isParsing() {
      return this.status === ImportState.Parsing;
    },
    isPaid() {
      return this.status === ImportState.Paid;
    },
    isProcessing() {
      return this.status === ImportState.Processing;
    },
    isParsed() {
      return this.status === ImportState.Parsed;
    },
    isCompleted() {
      return this.status === ImportState.Completed;
    },
    isShowTranscript() {
      return this.status >= ImportState.Paid;
    },
  },
  components: {
    Transcript,
    OwnerChargeComponent,
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;

    this.getUrbanRenewal();
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  },
  methods: {
    handleFileChange(files) {
      this.selectedFiles = files;
    },
    async uploadFiles() {
      if (!this.selectedFiles.length) return;

      this.status = ImportState.Parsing;
      this.uploadProgress = 0;
      const formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("files", this.selectedFiles[i]);
      }
      try {
        await this.parseFile(formData);
        this.$emit("upload-complete");
      } catch (error) {
        console.error("Upload failed:", error);
        this.status = ImportState.Failed;
      } finally {
      }
    },
    showImportUrbanDialog() {
      this.import_dialog = true;
    },
    importToUrban() {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/import_to_urban/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      let currentRegistration = this.$refs.transcript
        ? this.$refs.transcript.currentRegistration
        : null;
      if (currentRegistration == null) {
        return;
      }
      this.message = {
        title: this.$t("plan.transcribe"),
        text: "謄本匯入...",
      };

      console.log("currentRegistration", currentRegistration);
      let lbkey = currentRegistration.lbkey;
      let data = {
        urban_id: this.choose_urban ? this.choose_urban.id : null,
        lbkey: lbkey,
        filename: this.last_parsed_filenames,
        urban_name: this.new_urban_name,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response);
          currentObj.importDone();
        })
        .catch(function (error) {
          console.log(error);
          currentObj.status = ImportState.Failed;
          alert("謄本匯入失敗");
        })
        .finally(() => {
          // set timeout to close the dialog after 1 second
          this.import_dialog = false;
        });
    },
    importDone() {
      setTimeout(() => {
        // get the urban
        this.getUrbanRenewal();
        alert("匯入完成");
      }, 1000);
    },
    parseFile(formData) {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/parse/`;
      // let urban_name = this.$refs.transcribe.choose_urban.name;
      const currentObj = this;
      // upload with axios
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.reset_transcript();
      this.message = {
        title: this.$t("plan.transcribe"),
        text: "謄本解析中...",
      };

      this.axios
        .post(url, formData, config)
        .then(function (response) {
          currentObj.loading = false;
          let data = response.data;
          currentObj.setData(data);
        })
        .catch(function (error) {
          console.log(error);
          currentObj.status = ImportState.Failed;
          alert("解析失敗 請稍後再試");
        })
        .finally(() => {});
    },
    setSelectedRegistration() {
      this.$nextTick(() => {
        if (this.$refs.transcript != null) {
          // 確保在 DOM 更新後能正確獲取 transcript 引用
          if (this.transcripts.length > 0) {
            let lbkey = this.transcripts[0].lbkey;
            this.$refs.transcript.setSelectedRegistration(lbkey);
          }
        } else {
          console.log("Not set it");
        }
      });
    },

    setData(data) {
      let results = data.results;
      this.transcripts = results;
      let filename = data.filename;
      for (let transcript of results) {
        // loop the o_data and c_data if 住址 not in item then this.regnos_count += 1
        for (let item of transcript.o_data) {
          if (!item.hasOwnProperty("住址")) {
            this.regnos_count += 1;
          }
        }
        for (let item of transcript.c_data) {
          if (!item.hasOwnProperty("住址")) {
            this.regnos_count += 1;
          }
        }
      }
      this.owner_count = data.owner_count;
      this.charge = data.charge;
      this.pdf_files = data.pdf_files;
      this.user_balance = data.user_balance;
      console.log("owner count is", this.owner_count, " charge:", this.charge);
      console.log("send event to server", filename);

      this.last_parsed_filenames = filename;
      this.new_urban_name = data.urban_name;
      this.status = ImportState.Parsed;
      // test
      // this.onPaid();
    },
    reset_transcript() {
      this.regnos_count = 0;
      this.processed = 0;
      // if ref.transcript is not null, call reset method
      if (this.$refs.transcript) {
        this.$refs.transcript.reset();
      }
    },
    reset() {
      this.selectedFiles = [];
      this.transcripts = [];
      this.last_parsed_filenames = null;
      this.status = ImportState.Init;
      this.new_urban_name = "";
      this.uploadProgress = 0;
      this.selectedOption = 1;
      this.import_dialog = false;
      this.owner_count = 0;
      this.charge = 0;
      this.pdf_files = [];
      this.user_balance = 0;
      this.reset_transcript();
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      if (info.action == SocketEventType.TranscriptParseEvent) {
        let data = info.data;
        this.$refs.transcript.updateOwnerData(data);
        this.processed += 1;
        let progress = (this.processed / this.regnos_count) * 100;

        progress = progress > 100 ? 100 : progress;
        this.uploadProgress = progress;
      } else if (info.action == SocketEventType.TranscriptParseEnd) {
        this.status = ImportState.Completed;
      }
    },
    retrySendObj(data, maxRetries = 3, delay = 10000) {
      let retries = 0;
      this.status = ImportState.Processing;
      const attempt = () => {
        if (this.$socket.readyState === WebSocket.OPEN) {
          this.$socket.sendObj(data);
          console.log("Message sent successfully");
        } else if (retries < maxRetries) {
          retries++;
          console.log(
            `WebSocket not ready. Retrying in ${
              delay / 1000
            } seconds... (Attempt ${retries}/${maxRetries})`
          );
          setTimeout(attempt, delay);
        } else {
          console.error(`Failed to send after ${maxRetries} attempts`);
        }
      };

      attempt();
    },
    onPay() {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/balance/withdraw/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      let filenames = this.selectedFiles.map((file) => file.name).join(",");

      let data = {
        balance: this.charge,
        description: filenames,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response);
          currentObj.onPaid();
        })
        .catch(function (error) {
          console.log(error);
          // get the error message
          if (error.response.data) {
            let message = error.response.data;
            console.log("error message", message.error);
            if (message.error == "Insufficient balance") {
              alert("餘額不足");
            } else {
              console.log("error message", message.error);
              alert(message.error);
            }
          } else {
            alert("付款失敗");
          }
        });
    },
    onPaid() {
      console.log("pay it.", this.last_parsed_filenames);
      this.status = ImportState.Paid;
      this.setSelectedRegistration();
      this.retrySendObj({
        action: SocketEventType.TranscriptParseStart,
        filename: this.last_parsed_filenames,
      });
    },
  },
};
</script>

<style scoped>
.v-file-input {
  margin-bottom: 20px;
}
</style>
